import { $post } from '@/api/http.js'
import common from '@/api/common.js'
import tool from '@/util/tool.js'
import router from '../../router'
import store from '@/store'
import {consoleLog} from "echarts/lib/util/log";
class Program{
    /**
     * 获取应用列表信息
     * @param {number} type     查询类型 1=我的列表 2子用户列表 3删除的列表
     * @param {number} page     当前页
     * @param {number} limit    每页的条数
     * @param {object} extra    筛选的查询参数
     * @param {onject} fn
     */
    getProgramList(type,page,limit,extra,fn) {
        let param = { page,limit,type }
        if( extra ){
            if( extra.key ) param.key = extra.key
            if( extra.server_id ) param.server_id = extra.server_id
            if( extra.mobile) param.mobile = extra.mobile
            if( extra.status !=-1 ) param.status = extra.status
        }

        if( type ) param.type =type
        common.getDataList("getProgramList",param,1).then(res=>{
            fn(res);
        })
    }

    /**
     * 获取应用详情
     * @param {number} program_id
     * @param {function} cb
     */
    getProgramDetail(program_id,cb){
        $post('getProgramDetail', { program_id },true, 1).then(res => cb(res.data));
    }

    /**
     * 获取应用h5/pc预览信息
     * @param {function}} cb
     */
    getProgramUrl(cb){
        $post('getProgramUrl').then(res =>cb(res.data))
    }

    /**
     * 新增或编辑店铺信息
     * @param {string} sence add = 新增 edit=编辑
     * @param {object} data 保存信息
     * @param {object} fn
     */
    addOrEditProgram(sence,data,fn) {
        let api = sence == 'add' ? 'addProgram':'editProgram'
        if( !data.program_name){
            tool.message("请填写店铺名称","warning")
            return false
        }

        if( !data.logo ){
            tool.message("请上传店铺图片","warning")
            return false
        }

        $post(api,data,true,1).then(res=>{
            tool.message("添加成功")
            fn(res.data)
        })
    }

    /**
     * 创建农场购买订单
     * @param {Object}} param
     * @param {function} cb
     * @returns
     */
    createSaasProgramOrder(param,cb){
        let return_url = window.location.href
        if( !tool.returnMessage(param.program_name,"请填写农场名称")) return
        if( !tool.returnMessage(param.logo,"请上传农场图片")) return
        param.return_url = return_url
        $post("createSaasProgramOrder",param,true,1).then(res=>{
            if( param.pay_type == 'ali'){  //支付宝支付
                window.location.href = res.data.pay
                return
            }
            //微信支付
            cb(res.data)
        })
    }


    /**
     * 授权创建农场
     * @param {object} param
     */
    addOrEditProgramByAuth(param){
        $post('addProgramUseAuth', param,true, 1).then(() => {
            tool.message('创建成功');
            router.go(-1);
        })
    }

    /**
     * 改变店铺状态
     * @param {number}} program_id 店铺id
     * @param {number} type 操作类型 删除：1 恢复 2
     * @param {object} fn
     */
    changeProgramStatus( program_id,type,fn ){
        let title = ""
        if( type == 1 ) title="确认要将店铺删除吗？"
        if( type == 2 ) title="确认要将店铺恢复吗？"

        tool.confirm(title).then(()=>{
            $post("changeProgramStatus",{program_id,type},true,1).then(res=>{
                tool.message("操作成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 迁移小程序
     * @param {number} program_id
     * @param {number} user_id
     * @param {function} fn
     */
    migrateProgram(program_id,user_id,fn){
        if( !tool.returnMessage(user_id,'请选择要迁移的用户！')) return
        tool.confirm("确认迁移小程序吗？").then(()=>{
            $post('migrateProgram', {program_id,user_id},true, 1).then(res => {
                tool.message("迁移成功");
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 设置应用分支
     * @param {number} program_id
     * @param {number} server_id
     * @param {function} fn
     */
    setProgramServer(program_id,server_id,fn){
        if( !tool.returnMessage(server_id,'请选择要切换的分支')) return
        $post('upgradeServerProgram', {program_id,server_id}, true,1).then(res => {
            tool.message('分支切换成功')
            fn(res.data)
        })
    }

    /**
     * 设置应用天数
     * @param {object} param
     * @param {function} fn
     */
    setProgramTime(param,fn) {
        $post('setProgramTime', param, true,1).then(res => {
            tool.message('保存成功')
            fn(res.data)
        });
    }


    /**
     * 设置应用插件
     * @param {object} param
     * @param {function} fn
     */
    setProgramPlugin(param,fn) {
        let data = {
            program_id: param.program_id
        };

        let idArray = [];
        param.list.forEach(item => {
            if (item.is_use == 1) {
                idArray.push(item.plugin_id);
            }
        });
        data.plugin_id = idArray;
        $post('setProgramPlugin', data,true ,1).then(res => {
            tool.message('设置成功');
            fn(res.data)
        });
    }

    /**
     * 进入店铺管理后台
     * @param {number} program_id   店铺id
     * @param {number} end_days     剩余天数 -1表示不检测过期时间
     */
    intoProgram(program_id,end_days=-1) {
        //过期时间小于等于7天时
        if( end_days >0 && end_days<=7 ){
            let title = `应用还有${end_days}天到期，请及时续费，以免影响正常使用！`
            tool.confirm(title,'立即续费','知道了').then(()=>{
                router.push({path:'/shop/shopRenew',query:{id:program_id}})
            }).catch(()=>{
                $post("innerProgram",{program_id},false,1).then(res=>{
                    localStorage.setItem("program_id",program_id)
                    sessionStorage.removeItem('nav_index');
                    router.push("/miniapp")
                }).catch(()=>{})
            })
            return
        }

        $post("innerProgram",{program_id},false,1).then(res=>{
            localStorage.setItem("program_id",program_id)
            sessionStorage.removeItem('nav_index');
            router.push("/miniapp")
        }).catch(err=>{
            //应用过期提示
            if( err.errorCode === 201001 ){
                tool.confirm("应用已过期，请及时续费~",'立即续费','暂不续费').then(()=>{
                    router.push({path:'/shop/shopRenew',query:{id:program_id}})
                }).catch(()=>{})
            }
        })
    }
    /**
     * 获取店铺预订单信息
     * @param {number} program_id  店铺id
     * @param {string} type 订单类型 program_order=店铺购买订单
     * @param {function} fn
     */
    getShopPreOrder(data,fn){
        let param = {}
        if( data ){
            if( data.program_id ) param.program_id = data.program_id
            if( data.order_type ) param.order_type = data.order_type
            if( data.is_renew ) param.is_renew = data.is_renew
            if( data.is_saas ) param.is_saas = data.is_saas
        }

        $post("getPreOrder",param,true,1).then(res=>{
            fn(res.data)
        })
    }
    createShopOrder(program_id,pay_method,type,fn){
        if( !pay_method ){
            tool.message("请选择支付方式","warning")
            return
        }
        let param = { program_id ,order_type:type,is_renew:true,is_saas:true}
        $post("addOrder",param,true,1).then(res=>{
            fn(res.data)
        })
    }
    /**
     * 店铺订单支付
     * @param {number}} order_id 订单id
     * @param {string} type     订单类型 program_order=店铺购买订单
     * @param {number} pay_method 支付方式 微信扫描支付=1 支付宝支付2
     * @param {function} fn
     */
    shopOrderPay(order_id,type,pay_method,fn){
        let back_url = window.location.href;
        let payData = {
            order_type:type,
            id:order_id,
            pay_type: pay_method,
            return_url: back_url
        }
        $post("pay",payData,true,1).then(res=>{
            //status == 1表示该订单的支付金额为0
            if( res.data.status == 1 ){
                fn(res.data)
                return
            }
            //支付宝支付，直接跳转支付页面
            if( pay_method == 2){
                window.location.href = res.data.qr_code;
                return;
            }
            //微信支付，需要弹出弹框展示支付二维码
            if( pay_method == 1){
                fn(res.data)
            }
        })
    }
    /**
     * 检测订单是否已支付
     * @param {string} order_number 订单编号
     * @param {function} fn
     */
    checkIsPay(order_number,fn) {
        $post("queryWxNativeOrder",{order_number},true,1).then(res=>{
            if( res.data.is_ok ){
                tool.message("支付成功","warning")
                fn(res.data)
                return
            }

            tool.message("您还未支付！","warning")
        })
    }
    /**
     * 获取应用购买订单
     * @param {number} page
     * @param {number} limit
     * @param {object} extra
     * @param {function} fn
     */
    getAppOrder(page,limit,extra,fn) {
        let param = { page,limit }
        if( extra) {
            if( extra.order_number ) param.order_number = extra.order_number
            if( extra.program_id ) param.program_id = extra.program_id
            if( extra.pay_method ) param.pay_method = extra.pay_method
            if( extra.pay_status ) param.pay_status = extra.pay_status
            if( extra.server_id ) param.server_id = extra.server_id
            if( extra.order_type !="all") param.order_type = extra.order_type
        }
        common.getDataList("getProgramOrderList",param,true,1).then(res=>fn(res))
    }

    /**
     * 获取插件订单
     * @param {number} page
     * @param {number} limit
     * @param {extra} extra
     * @param {function} fn
     */
    getPluginOrder(page, limit,extra,fn) {
        let param = { page,limit}
        if( extra && extra.program_id ) param.program_id = extra.program_id
        common.getDataList("getPluginOrderList",param).then(res=>fn(res))
    }


    /**
     * 设置应用短信条数
     * @param {number} param
     * @param {function} fn
     */
    setProgramShoetMsg(param,fn) {
        if( !param.num || param.num <=0 ){
            tool.message("请输入操作短信条数")
            return
        }
        $post('addOrSubSmsNumber', param, true,1).then(res => {
            tool.message('操作成功');
            fn(res.data)
        })
    }

    /**
     * 获取应用插件
     * @param {number} program_id
     * @param {function} fn
     */
    getProgramPlugin(program_id,fn){
        $post('getProgramPlugin', { program_id},true, 1).then(res => fn(res.data.list));
    }

    /**
     * 获取h5审核列表
     * @param {number} page
     * @param {number} limit
     * @param {object} extra
     * @param {function} cb
     */
    getH5Program(page,limit,extra,cb){
        let param = { page, limit }
        if( extra ){
            if( extra.key ) param.key = extra.key
            if( extra.h5_is_ok ) param.h5_is_ok = extra.h5_is_ok
        }
        common.getDataList("getH5CheckList",param,1).then(res=>cb(res))
    }

    /**
     * h5应用通过审核
     * @param {number} program_id
     * @param {function} cb
     */
    h5ProgramCheck(program_id,cb){
        $post('changeCheckStatus', { program_id },true, 1).then(res => {
            tool.message("操作成功")
            cb(res.data)
        });
    }

    /**
     * 检测当前用户是否可创建农场
     * @param {*} cb
     */
    checkCanCreateProgram(cb){
        $post("getCanCreateMinPro",{},true,1).then(res=>cb(res))
    }

    /**
     * 获取农场操作记录
     * @param {number} page
     * @param {number} limit
     * @param {object} extra
     * @param {function} fn
     */
    getProgramOperatRecord(page, limit,extra,fn) {
        let param = { page, limit }
        if( extra && extra.type ) param.type = extra.type
        common.getDataList("getAppRecord",param,1).then(res=>fn(res))
    }

    /**
     * 获取授权剩余个数
     * @param {function} cb
     */
    getProgramAuthCount(cb) {
        $post('getCanCreateMinPro', {},true, 1).then(res => cb(res.data));
    }

    /**
     * 获取农场购买订单
     * @param {number} page
     * @param {number} limit
     * @param {object} extra
     * @param {function} cb
     */
    getProgramOrder(page,limit,extra,cb) {
        let param = { page, limit }
        if( extra ){
            if( extra.order_number ) param.key = extra.order_number
            if( extra.status || extra.status == 0 ) param.order_status = extra.status
            if( extra.searchTime.length > 0 ){
                param.start_time = moment(extra.search.searchTime[0]).format("YYYY-MM-DD");
                param.end_time = moment(extra.search.searchTime[1]).format("YYYY-MM-DD");
            }
        }
        common.getDataList("getOrderList",param,1).then(res=>cb(res))
    }

    /**
     * 删除农场购买订单
     * @param {number} id
     * @param {function} cb
     */
    deleteProgramOrder(id,cb) {
        tool.confirm("确认要删除该角色信息吗?").then(()=>{
            $post('deleteUserOrder', { id },true, 1).then(res => {
                tool.message("删除成功");
                cb(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 保存农场订单备注
     * @param {number} order_id
     * @param {string} remark
     * @param {function} cb
     */
    saveProgramOrderRemark(order_id,remark,cb){
        $post('createOrUpdateRemark',{ id:order_id ,remark},true,1).then(res=>{
            tool.message("保存成功")
            cb(res.data)
        })
    }

    /**
     * 获取h5/pc审核域名
     * @param {number} page
     * @param {number} limit
     * @param {object} extra
     * @param {function} cb
     */
    getH5SiteAudit(page, limit,extra,cb) {
        let param = { page, limit };
        if( extra ){
            if( extra.audit ) param.audit = extra.audit
        }
        if( extra.sence == 'h5'){
            common.getDataList("getAppSiteList",param,true,1).then(res=>cb(res))
        }
        if( extra.sence == 'pc' ){
            common.getDataList("getWebSiteList",param,true,1).then(res=>cb(res))
        }
    }

    /**
     * h5审核域名
     * @param {string} sence pc / h5
     * @param {object} param
     * @param {function} cb
     */
    h5SiteAudit(sence,param,cb){
        let api=''
        if( sence == 'h5' ) api ='setAppSiteAudit'  //h5
        if( sence == 'pc' ) api ='setWebSiteAudit'  //pc
        $post(api, param,true,1).then(res => {
            tool.message('操作成功')
            cb(res.data)
        })
    }

    /**
     * h5/pc审核域名记录
     * @param {string} sence
     * @param {number} id
     * @param {function} cb
     */
    h5SiteAuditLog(sence,id,cb){
        let api=''
        if( sence == 'h5' ) api ='getAppSiteAuditLog'  //h5
        if( sence == 'pc' ) api ='getWebSiteAuditLog'  //pc
        $post(api, { id },true,1) .then(res =>cb(res.data)).catch(() => {});
    }

    /**
     * 获取全部插件
     * @param {function} cb
     */
    getAllPlugin(cb) {
        $post('cloudGetPluginList', {},true, 1).then(res => cb(res.data));
    }

    /**
     * 从云端购买插件
     * @param {number} plugin_id
     * @param {function} cb
     */
    buyPluginFormCloud(plugin_id,cb){
        $post('cloudBuyPlugin', { plugin_id },true, 1).then(res => cb(res.data))
    }

    /**
     * 刷新缓存
     * @param {function} cb
     */
    cloudRefreshCache(cb){
        $post('cloudRefreshCache', {},true, 1).then(res => {
            tool.message('更新成功')
            cb(res.data)
        })
    }

    /**
     * 获取我的所有插件
     * @param {number} page
     * @param {number}} limit
     * @param {function} cb
     */
    getMyPlugin(page,limit,cb){
        let param = { page, limit };
        common.getDataList("localGetPluginList",param,1).then(res=>cb(res))
    }

    /**
     * 修改我的插件信息
     * @param {string} sence price修改价格信息 state修改状态信息
     * @param {object} param
     * @param {function} cb
     * @returns
     */
    updateMyPluginPirce(sence,param,cb){
        if( sence == 'price'){
            if( !tool.returnMessage(param.amount_actual,'请输入实际价格')) return
            if( !tool.returnMessage(param.amount_origin,'请输入优惠价格')) return
        }

        $post('localSetPlugin', param,true, 1).then(res => {
            tool.message('设置成功')
            cb(res.data)
        })
    }

    /**
     * 获取saas端插件订单
     * @param {number} page
     * @param {number} limit
     * @param {function} cb
     */
    getSaasPluginOrder(page,limit,cb){
        let param ={page,limit}
        common.getDataList("cloudGetPluginOrderList",param,1).then(res=>cb(res))
    }

    checkSassProgramOrder(order_sn,cb){
        $post("checkSassProgramOrder",{order_sn},true,1).then(res=>{
            if( res.data.is_ok ){
                cb(res.data)
                return
            }
            tool.message("您还未支付！","warning")
        })
    }

    /**
     * 检测是否已经创建了某个版本的试用版本了
     * @param {Number} server_id    版本id
     * @param {Function} cb
     */
    checkCreateProgram(server_id,cb){
        $post("checkIsCreateProgram",{server_id,is_trail:1},true,1).then(res=>cb(res.data))
    }

    /**
     * 获取当前农场可升级的版本信息
     * @param {Function}} cb
     */
    getProgramUpgradeVersion(cb){
        $post("upgradeProgramList",{},true,1).then(res=>cb(res.data))
    }

    /**
     * 创建农场 预/支付 订单
     * @param {Object} param
     * @param {function} cb
     */
    createUpgradeProgramOrder(param,cb){
        $post("upgradeProgram",param,true,1).then(res=>cb(res.data))
    }
}

const programModel = new Program()
export default programModel
